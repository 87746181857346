import React from 'react'
import { connect } from 'react-redux'
import format from "date-fns/format"
import { changeVariable } from '../../actions/variables'
import OrderDetailModal from "./OrderDetailModal"
import { orderFetch } from '../../actions'

const OrderItem = ({
    props,

    changeVariable,
    orderFetch
}) => {

    const {
        orderData,
        
    } = props

    const {

		created_at,
		userName,
		_id,
		price,
		userAddress,
        userPincode,
        userCity,
        userState,
		order,

	} = orderData
    
    const displayProductUpdateModal = () => {
        orderFetch(_id)
        changeVariable("modal_order_flag",true)
	}
  return (
    <>
    <div className="bg-slate-100 shadow border rounded border-slate-200 p-2.5 cursor-pointer" onClick={ () => displayProductUpdateModal() }>
    <div className="flex justify-between">
        <div>
            <div className="text-sm text-slate-800">Order #{ _id }</div>
            {/* <div className="text-xs text-slate-800">Items : { typeof order !== "undefined" ? order.length : 0 }</div> */}
            {/* <div className="text-xs text-slate-800">{ vendor.name }</div> */}
        </div>
        <div className="text-right">
            <div className="text-xs text-slate-600">₹{ price }</div>
            <div className="text-xs text-slate-600">{ userName }</div>
            <div className="text-xs text-slate-600">{ typeof userAddress !== "undefined" ? userCity : "-" },{ typeof userAddress !== "undefined" ? userState : "-" }</div>
            <div className="text-xs text-slate-600">{ typeof userAddress !== "undefined" ? userPincode : "-" }</div>
        </div>
    </div>
    <div className="mt-1">
        <div className="text-xs text-slate-600">{ format( new Date( created_at ), "do MMMM yyyy, hh:mm aa" ) }</div>
    </div>
</div>

</>
)
}

const mapStateToProps = ( state, props ) => {


	return{

		props

	}

}

const mapActionsToProps = {

	changeVariable,
    orderFetch

}
export default connect(mapStateToProps,mapActionsToProps)( OrderItem)