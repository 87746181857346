import React from 'react'
import ContactItem from './ContactItem'

const ContactList = ({contacts_array}) => {
  console.log(contacts_array,"jrns")
  return (
    <>
    <div className='productlist w-full flex flex-row flex-wrap h-screen '>
        {contacts_array&&contacts_array.map((value)=>{
            return <ContactItem contactData = {value}/>
        })}
    </div>
    </>
  )
}

export default ContactList