import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { getMessages } from '../../actions'

// getMessage
import ContactList from './ContactList'

const Contacts = ({
    contacts_array,

    getMessages
}) => {
    
    useEffect(() => {
      getMessages()
    }, [])
    
  return (
    <div>
        <div className='products mt-3  '><ContactList contacts_array  ={contacts_array} /></div>
        
      </div>
  )
}

const mapStateToProps = (state)=>{
    const {contacts_array} = state.variables
    return {
        contacts_array
    }
}

const mapActionsToProps = {
    getMessages
}

export default connect(mapStateToProps,mapActionsToProps)(Contacts)