import React from "react"

// STYLING


const ProductImage = ( props ) => {

	const { image } = props
	if( typeof image !== "undefined" && image !== "" ){

		return(

			<div className="block h-12 mr-2 mt-2 relative w-52">
				<img className="object-contain"
					src={ image }
					layout={"fill"}
					objectFit={'contain'}
					alt={ "category-image" }
					unoptimized={ true }
				/>
			</div>

		)

	}
	return(

		<div/>

	)

}

export default ProductImage
