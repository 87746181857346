import React from 'react'
import CouponUpdateModal from './CouponUpdateModal'
import { connect } from 'react-redux'
import { changeVariable } from '../../actions/variables'
const CouponItem = ({
  modal_coupon_flag,
  coupon_update_flag,
  value,
  changeVariable
}) => {
  const displayCouponModal=()=>{
    changeVariable("coupon_data",value)
    changeVariable("coupon_update_flag",true)
    
  }
  return (
    <>
      <div className="w-22 h-22  p-10 m-1 bg-white rounded border border-light-grey shadow cursor-pointer bg-slate-100" onClick={() => { displayCouponModal() }} >
        <div className=''>{value._id}</div>
        <div className="text-slate-900 text-base font-bold">{value.code}</div>
        {/* <div className="text-slate-900 text-base font-bold">{value.amount}</div> */}
        <div className="text-slate-900 text-base font-bold">is_active {value.is_active?"true":"false"}</div>
        <div className="text-slate-900 text-base font-bold">one_Time {value.one_time?"true":"false"}</div>
        <div className="text-slate-900 text-base font-bold">Percentage {value.amount}</div>
        <div className="text-slate-900 text-base font-bold">limit {value.limit}</div>
      </div>
      {
        coupon_update_flag && <CouponUpdateModal />
      }

    </>
  )
}


const mapStateToProps = (state) => {
  const {
    modal_coupon_flag,
    coupon_update_flag,
  } = state.variables
  return {
    modal_coupon_flag,
    coupon_update_flag
  }
}

const mapActionsToProps = {
  changeVariable
}
export default connect(mapStateToProps, mapActionsToProps)(CouponItem)