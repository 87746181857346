import logo from './logo.svg';
import './App.css';
import Navigation from './components/navigation/Navigation';
import Products from './components/products/Products';
import Orders from "./components/orders/Orders"
import {connect} from "react-redux"

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Categories from './components/categories/Categories';
import Contacts from './components/contact/Contacts';
import Login from './components/login/Login';
import { checkLoggedIn } from './actions';
import { useEffect } from 'react';
import Coupons from './components/coupons/Coupons';

function App({
  isLogin,

  checkLoggedIn
}) {

  useEffect(() => {
    checkLoggedIn()
  }, [])
  
  
  return (
    <Router>
      <div className="App bg-sky-100 h-fit">
        
        {!isLogin ?
          <Login /> :
          <div>
          <Navigation />
          <Switch>
            <Route exact path={'/products'}><Products /></Route>
            <Route exact path={'/categories'}><Categories /></Route>
            <Route exact path={"/products/:category"}><Products /></Route>
            <Route exact path={"/orders"}><Orders /></Route>
            <Route exact path={"/orders/pending"}   ><Orders orderType="/pending" /></Route>
            <Route exact path={"/orders/accepted"}   ><Orders orderType="/accepted" /></Route>
            <Route exact path={"/orders/cancelled"}   ><Orders orderType="/cancelled" /></Route>
            <Route exact path={"/contacts"}   ><Contacts /></Route>
            <Route exact path={"/coupons"}   ><Coupons /></Route>
            {/* <ProductsList/> */}

          </Switch></div>}
      </div>
    </Router>
  );
}
const mapStateToProps = (state)=>{
  const {
    isLogin
  }=state.variables 
  return {
    isLogin
  }
}

const mapActionsToProps = {
  checkLoggedIn
}
export default connect(mapStateToProps,mapActionsToProps)(App);
