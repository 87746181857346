import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { productsFetch, productsFetchbySKU, productsFetchbycategory, updateProduct, uploadProduct } from '../../actions'
import ProductItem from './ProductItem'
import ProductUpdateModal from './ProductUpdateModal'

const ProductsList = ({
    productsData,
    products_array,
    categories_array,


    productsFetchbycategory,
    productsFetchbySKU,
    productsFetch,
    uploadProduct,
    updateProduct
}) => {



    // useEffect(() => {
    //     productsFetch()

    // }, products_array)

    const [search, setSearch] = useState("")
    const [data, setData] = useState({
        category: "",
        type: "fashion"
    })

    const [file, setFile] = useState(null)

    const handleFileChange = (e) => {
        // console.log(e.target.files,35)
        setFile(e.target.files[0]);

    };
    const submitFile = (type) => {
        if (type == "upload") {
            uploadProduct(file)
        }
        else if (type == "update") {
            updateProduct(file)
        }
    }


    const updateState = (event) => {
        // const name = event.target.name
        // const value = event.target.value
        setData({ ...data, category: event.target.value })
        // console.log("change",data.category)
    }
    useEffect(() => {
        if (search.length > 2) {
            productsFetchbySKU(search)
        }
        else {
            productsFetch()
        }
    }, [search])

    useEffect(() => {
        productsFetchbycategory(data)
        console.log(data.category, "herh e")
    }, [data.category])


    return (
        <>
            <div className="flex justify-between">
                <h1 className="text-2xl text-slate-900 font-semibold">Products</h1>
                <div>
                    Total Products: {products_array&&products_array.length}
                </div>
                <div className=" flex">
                    <button className="relative w-96 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm" id="headlessui-listbox-button-:r7:" type="button" aria-haspopup="true" aria-expanded="false" fdprocessedid="50734">
                        {/* <span className="block truncate">Maharsaja Exports</span> */}
                        <select className='block truncate' name="category" id="" onChange={(e) => { updateState(e) }} >
                            {categories_array &&
                                categories_array.map((category) => { return <option value={category._id}>{category.title}</option> })}
                        </select>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-gray-400">
                                <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                            </svg>
                        </span>
                    </button>
                    <div className="relative w-96 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm" id="headlessui-listbox-button-:r7:" type="button" aria-haspopup="true" aria-expanded="false" fdprocessedid="50734" >
                        <input type="text" className='w-full ' onChange={(e) => { setSearch(e.target.value) }} placeholder='SKU' value={search} />
                    </div>
                    <div className='flex flex-col'>
                        <div>
                            <input type="file" onChange={(e) => { handleFileChange(e) }} id="" />
                            <button type="button" className='p-2 rounded bg-black text-white' onClick={() => { submitFile("upload") }} >upload file</button>
                        </div>
                        <div>
                            <input type="file" placeholder='update' onChange={(e) => { handleFileChange(e) }} id="" />
                            <button type="button" className='p-2 rounded bg-black text-white' onClick={() => { submitFile("update") }} >update file</button>
                        </div>
                    </div>
                </div>

            </div>
            <div className='productlist w-full flex flex-row flex-wrap h-screen '>

                {productsData && productsData.map((value) => {
                    return <ProductItem
                        productData={value}
                    />
                })}
                <ProductUpdateModal />


            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const {
        products_array,
        categories_array
    } = state.variables
    return {
        products_array,
        categories_array

    }
}
const mapActionsToProps = {
    productsFetch, productsFetchbycategory,
    productsFetchbySKU,
    uploadProduct,
    updateProduct
}
export default connect(mapStateToProps, mapActionsToProps)(ProductsList)