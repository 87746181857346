import React, { useState } from 'react'
import { couponAdd } from '../../actions'
import { connect } from 'react-redux'

const CouponAddModal = ({
  couponAdd
}) => {
  const [state, setState] = useState({
    code: "",
    is_active: true,
    one_time: true,
    amount: "",
    limit: ""
  })
  
  const updateState = (event) => {
    const variable = event.target.name
    const value = event.target.value
    setState({ ...state, [variable]: value })
  }
  return (
    <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">

      <div class="relative w-full h-full max-w-2xl md:h-auto dark:bg-gray-200">
        <div className='flex w-44 mx-auto justify-between'>
          <div className='details p-2 bg-white'>Details</div>
          <div className='images p-2 bg-white'>Images</div>
        </div>
        <div class="relative  rounded-lg shadow ">


          <form className='p-5'>
            <div class="relative z-0 w-full mb-6 group">
              <input type="text" onChange={(e) => { updateState(e) }} value={state.code} name="code" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
              <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title</label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
              <input type="text" onChange={(e) => { updateState(e) }} value={state.amount} name="amount" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
              <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Amount</label>
            </div>
            <div class="relative z-0 w-full mb-6 group">
              <input type="text" onChange={(e) => { updateState(e) }} value={state.limit} name="limit" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
              <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">limit</label>
            </div>

            <div class="relative z-0 w-full mb-6 group flex items-center">
              <input type="checkbox" onChange={(e) => { setState({ ...state, is_active: state.is_active === true ? false : true }) }} checked={state.is_active} name="is_active" id="floating_email" class="block mr-2 " placeholder=" " required />
              <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">on Home</label>
            </div>
            <div class="relative z-0 w-full mb-6 group flex items-center">
              <input type="checkbox" onChange={(e) => { setState({ ...state, one_time: state.one_time === true ? false : true }) }} checked={state.one_time} name="one_time" id="floating_email" class="block mr-2 " placeholder=" " required />
              <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">is_active</label>
            </div>
            <div className='flex justify-between'>
              <div>
                <button type="button" onClick={() => { couponAdd(state) }} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
              </div>
              <button type='button' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'  >Close</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const {

  } = state.variables
  return {
  }
}


const mapActionsToProps = {
  couponAdd

}
export default connect(mapStateToProps,mapActionsToProps)(CouponAddModal)