import React from 'react'
import { connect } from 'react-redux'
import { changeVariable } from '../../actions/variables'

const ProductItem = ({ props, changeVariable }) => {
	const {
		title, price,full_price, stock, sku, image,
		type,
		metal,
		description_short,
		description,
		category,
		_id,
		is_active,
		onHome,
		bullet_points
	} = props.productData
	// console.log(props.productData)
	const display_product_modal = () => {
		changeVariable("modal_product_id",_id)
		changeVariable("modal_product_title", title)
		changeVariable("modal_product_price", price)
		changeVariable("modal_product_full_price", full_price)
		changeVariable("modal_product_image", image)
		changeVariable("modal_product_type", type)
		changeVariable("modal_product_metal", metal)
		changeVariable("modal_product_sku", sku)
		changeVariable("modal_product_stock", stock)
		changeVariable("modal_product_description_short", description_short)
		changeVariable("modal_product_description", description)
		changeVariable("modal_product_category", category)
		changeVariable("modal_product_is_active", is_active)
		changeVariable("modal_product_bullet_points", bullet_points)
		changeVariable("modal_display_flag",true)
		changeVariable("modal_product_on_home",onHome)
	}


	return (

		<div className="w-72 h-fit  p-2.5 m-1 bg-white rounded border border-light-grey shadow cursor-pointer bg-slate-100" onClick={() => { display_product_modal() }}  >
			<div className="text-slate-900 text-base font-bold">{title} {_id}</div>
			<div className="flex mt-1">
				<div className="text-black text-sm mr-2.5">Price - </div>
				<div className="text-black text-sm mr-2.5">₹{price}</div>
				{/* <div className="text-black text-sm mr-2.5 line-through">₹{ full_price }</div> */}
				<div className="text-black text-sm">{ onHome?"onHome":"" }</div>
			</div>
			<div className="text-grey text-xs mt-1">Stock - {stock}</div>
			<div className="text-grey text-xs mt-1">is_active - { is_active }</div>
			<div className="text-grey text-xs mt-1">SKU - {sku}</div>
			<div className="text-grey text-xs mt-1">category - {category&&category.title}</div>
			<div className='float-right'><img className='h-32 w-32' src={image.split(",")[0]} alt="" /></div>
		</div>


	)
}


const mapStateToProps = (state, props) => {


	return {

		props

	}

}

const mapActionsToProps = {
	changeVariable
}

export default connect(mapStateToProps, mapActionsToProps)(ProductItem)