import React, { useEffect } from 'react'
import { useState } from 'react'

import { connect } from 'react-redux'
import { categoryUpdate, hideModal } from '../../actions'
import { uploadFiles } from '../../actions/upload/uploadfile'

import ProductImages from "../products/ProductImages"

const CategoryUpdateModal = ({
    modal_category_id,
    modal_category_priority_id,
    modal_category_title,
    modal_category_onHome,
    modal_category_is_active,
    modal_category_image,
    modal_category_banner_image,
    modal_category_flag,
    modal_category_mastHead,
    modal_category_banner,
    modal_category_description,
    files,
    banner,

    hideModal,
    uploadFiles,
    categoryUpdate
}) => {


    const [state, setState] = useState({
        category_id: "",
        category_title: "",
        // product_price: "",
        category_images: [],
        category_banner_image:[],
        category_is_active: true,
        category_onHome: false,
        category_mastHead: false,
        category_banner: false,
        category_description: "",
        category_priority_id:""
    })

    const uploadFilesFunction = (event) => {

        uploadFiles(event.target.files)

    }

    useEffect(() => {
        setState({
            ...state,
            category_id: modal_category_id,
            category_priority_id:modal_category_priority_id,
            category_title: modal_category_title,
            category_images: typeof modal_category_image !== "undefined" && modal_category_image.length > 0 ? modal_category_image.split(",") : [],
            category_banner_image:modal_category_banner_image,
            category_is_active: modal_category_is_active,
            category_onHome: modal_category_onHome,
            category_mastHead: modal_category_mastHead,
            category_banner: modal_category_banner,
            category_description: modal_category_description
        })

    }, [modal_category_title])


    const updateState = (event) => {
        const variable = event.target.name
        const value = event.target.value
        setState({ ...state, [variable]: value })
    }

    useEffect(() => {
        if (files !== null) {
            
            console.log("Here ", files)
            console.log("here2", files.data)
            var images = []
            {
                files.data ?
                files.data.map((value, index) => {
                    images.push(value.secure_url)
                }) :
                images.push(files.secure_url)
            }
            console.log(banner,84)
            if(banner==true){
                setState({ ...state, category_banner_image: images })
            }else{
                setState({ ...state, category_images: images })
            }
        
        }
    }, [files])



    const [option, setOption] = useState("details")
    return (
        <div>

            {/* <button data-modal-target="defaultModal" data-modal-toggle="defaultModal" class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                Toggle modal
            </button> */}

            {modal_category_flag &&
                <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
                    <div class="relative w-full h-fit max-w-2xl md:h-auto  dark:bg-gray-200">
                        <div className='flex w-44 mx-auto justify-between'>
                            <div className='details p-2 bg-white cursor-pointer'  onClick={()=>{setOption("details")}}>Details</div>
                            <div className='images p-2 bg-white cursor-pointer'onClick={()=>{setOption("images")}}>Images</div>
                        </div>
                        <div class="relative  rounded-lg shadow">

                            {option=="details"?
                            <form className='p-5'>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.category_title} name="category_title" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.category_description} name="category_description" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">description</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.category_priority_id} name="category_priority_id" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Priority Number</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group flex items-center">
                                    <input type="checkbox" onChange={(e) => { setState({ ...state, category_onHome: state.category_onHome === true ? false : true }) }} checked={state.category_onHome} name="category_onHome" id="floating_email" class="block mr-2 " placeholder=" " required />
                                    <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">on Home</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group flex items-center">
                                    <input type="checkbox" onChange={(e) => { setState({ ...state, category_is_active: state.category_is_active === true ? false : true }) }} checked={state.category_is_active} name="category_is_active" id="floating_email" class="block mr-2 " placeholder=" " required />
                                    <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">is_active</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group flex items-center">
                                    <input type="checkbox" onChange={(e) => { setState({ ...state, category_mastHead: state.category_mastHead === true ? false : true }) }} checked={state.category_mastHead} name="category_mastHead" id="floating_email" class="block mr-2 " placeholder=" " required />
                                    <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">MastHead</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group flex items-center">
                                    <input type="checkbox" onChange={(e) => { setState({ ...state, category_banner: state.category_banner === true ? false : true }) }} checked={state.category_banner} name="category_banner" id="floating_email" class="block mr-2 " placeholder=" " required />
                                    <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Banner</label>
                                </div>
                                <div className='flex justify-between'>
                                    <div>
                                        <button type="button" onClick={() => { categoryUpdate(state) }} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                                        <button type='button' className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Delete</button>
                                    </div>
                                    <button type='button' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' onClick={() => { hideModal("categoryupdate") }} >Close</button>
                                </div>
                            </form>:
                            <div className='py-5'>

                                <ProductImages images={state.category_images} />
                                <form >
                                    <div class="relative z-0 w-full mb-6 group">

                                        <input type="file" name="product_images" onChange={(files) => { uploadFilesFunction(files) }} multiple={true} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " />
                                        <label for="floating_email" class="">Image</label>
                                    </div>
                                </form>

                                <form >
                                    <div class="relative z-0 w-full mb-6 group">

                                        <input type="file" name="product_images" onChange={(event) => { uploadFiles(event.target.files,true) }} multiple={true} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " />
                                        <label for="floating_email" class="">banner</label>
                                    </div>
                                </form>
                                <ProductImages images={[state.category_banner_image]} />
                            </div>}
                        </div>
                    </div>
                </div>}
        </div>
    )

}

const mapStateToProps = (state) => {
    const { modal_category_title,
        modal_category_priority_id,
        modal_category_onHome,
        modal_category_is_active,
        modal_category_image,
        modal_category_banner_image,
        modal_category_id,
        modal_category_flag,
        modal_category_mastHead,
        modal_category_banner,
        modal_category_description,
        files,
        banner,
    } = state.variables
    return {
        modal_category_title,
        modal_category_priority_id,
        modal_category_onHome,
        modal_category_is_active,
        modal_category_image,
        modal_category_banner_image,
        modal_category_id,
        modal_category_flag,
        modal_category_mastHead,
        modal_category_banner,
        modal_category_description,
        files,
        banner,
    }
}


const mapActionsToProps = {
    hideModal,
    uploadFiles,
    categoryUpdate
}
export default connect(mapStateToProps, mapActionsToProps)(CategoryUpdateModal)