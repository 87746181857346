import React, { useEffect } from 'react'
import CategoryItem from './CategoryItem'
import {categoriesFetch} from "../../actions"
import { connect } from 'react-redux'
import CategoryAddModal from './CategoryAddModal'
import Notification from '../notification'

const Categories = ({
  categories_array,
  add_category_flag,

  categoriesFetch,
}) => {

  useEffect(() => {
    categoriesFetch()
  }, [])
  
  


  return (
    <div className='products mt-3 flex flex-row flex-wrap'>
        {categories_array&&
        categories_array.map((value)=>{return <CategoryItem value={value}/>})
        }
        {/* <Catego */}
        {add_category_flag&&
        <CategoryAddModal/>}
        <Notification/>
    </div>
  )
}

const mapStateToProps =(state) =>{
  const {
    categories_array,
    add_category_flag
  }= state.variables
  return {
    categories_array,
    add_category_flag
  }
}

const mapActionsToProps = {
  categoriesFetch
}
export default connect(mapStateToProps,mapActionsToProps)( Categories)