import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"

export const categoryAdd = ( data ) => {

    return (dispatch)=> {

        categoryAddHelper({ data, dispatch })

    }

}
const categoryAddHelper = async ({ data, dispatch }) => {

    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification_flag",
            value: true

        }

    })
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification",
            value: "updating product..."

        }

    })
    console.table( data )
    const token = await localStorage.getItem("token")
    const headers = {

        "Content-Type": "application/json",
        "authorization": `${token}`

    }
    console.log(data,"rdkmgh")
    const body = JSON.stringify({
        title: data.category_title,
        onHome:data.category_onHome,
        image: !data.category_images?"":data.category_images.toString(),
        is_active: data.category_is_active,
        description:data.category_description
    })
    console.log(body)
    const config = {

        body,
        headers,
        method: "POST",

    }
    const url = base_url+"/category/addcategory"

    // console.log( config )

    try {

        fetch(url, config)
        .then(response => response.json())
        .then(responseJson => {

            // console.log( responseJson )
            if( responseJson.status === 200 ){
                console.log(responseJson)
                // productsFetchByVendorHelper({ vendor_uuid: data.product_vendor_uuid, page: data.page_id, dispatch })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification",
                        value: responseJson.message

                    }

                    
                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: false

                    }

                })
                window.location.reload()
            } else {

                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: true

                    }

                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification",
                        value: responseJson.message

                    }

                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: false

                    }

                })

            }

        })

    } catch (err) {

        console.log("error")

    }

}
