import React, { useEffect } from 'react'
import Notification from '../notification'
import CouponItem from "./CouponItem"
import CouponAddModal from './CouponAddModal'
import { getCoupons } from '../../actions'
import { connect } from 'react-redux'
import { changeVariable } from '../../actions/variables'
const Coupons = ({
  add_coupon_flag,
  coupons_array,
  getCoupons,

  changeVariable
}) => {
  useEffect(() => {
    getCoupons()
  }, [])

  return (
    <>
      <button onClick={() => { changeVariable("add_coupon_flag", true) }}>Add the Coupon</button>

      <div className='products mt-3 flex flex-row flex-wrap'>
        {coupons_array && coupons_array.map((value, index) => { return <CouponItem key={index} value={value} /> })
        }
        {/* <Catego */}
        {add_coupon_flag &&
          <CouponAddModal />}
        <Notification />
      </div>
    </>

  )
}


const mapStateToProps = (state) => {
  const {
    add_coupon_flag,
    coupons_array
  } = state.variables
  return {
    add_coupon_flag,
    coupons_array
  }
}

const mapActionsToProps = {
  getCoupons,
  changeVariable
}
export default connect(mapStateToProps, mapActionsToProps)(Coupons)