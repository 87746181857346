import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"

export const couponUpdate = (data) => {

    return (dispatch) => {

        couponUpdateHelper({ data, dispatch })

    }

}
const couponUpdateHelper = async ({ data, dispatch }) => {
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification_flag",
            value: true

        }

    })
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification",
            value: "coupon updating"

        }

    })
    const token = await localStorage.getItem("token")
    const headers = {

        "Content-Type": "application/json",
        "authorization": `${token}`

    }
    const body = JSON.stringify({
        code: data.code,
        is_active: data.is_active,
        one_time: data.one_time,
        amount: data.amount,
        limit: data.limit,
    })
    const config = {

        body,
        headers,
        method: "PATCH",

    }
    const url = base_url + `/coupon/updatecoupon/${data.id}`
    try {

        fetch(url, config)
            .then(response => response.json())
            .then(responseJson => {

                console.log( responseJson )
                if (responseJson.status === 200) {
                    console.log(responseJson)
                    // productsFetchByVendorHelper({ vendor_uuid: data.product_vendor_uuid, page: data.page_id, dispatch })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification",
                            value: responseJson.message

                        }


                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification_flag",
                            value: false

                        }

                    })
                    window.location.reload()
                } else {

                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification_flag",
                            value: true

                        }

                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification",
                            value: responseJson.message

                        }

                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification_flag",
                            value: false

                        }

                    })

                }

            })

    } catch (err) {

        console.log("error")

    }
}