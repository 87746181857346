import React from "react"

import ProductImage from "./ProductImage"

// STYLING


const ProductImages = ( props ) => {

	var { images } = props
	console.log( "image is ", images )
	if( typeof images !== "undefined" && images !== "" ){

		return(

			<div className="flex flex-row mt-5 h-40 w-40 object-contain">
				{console.log(images,"dgnjdbngdfgvjnds",typeof images)}
				{
					
					images.map( ( value, index ) => {

						return(
							
							<ProductImage
								image={ value }
								key={ "product-image-"+index }
							/>

						)

					})

				}
			</div>

		)

	}
	return(

		<div/>

	)

}

export default ProductImages
