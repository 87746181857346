import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { categoriesFetch, productsFetch,productsFetchbycategory } from '../../actions'
import ProductsList from './ProductsList'
import ProductsAddModal from './ProductsAddModal'
import Notification from "../notification/"
const Products = ({
  categories_array,
  products_array,add_product_flag,

  productsFetch,
  categoriesFetch,
  
}) => {

  const { category } = useParams()

  // if (!category) {

    useEffect(() => {
      productsFetch()
      categoriesFetch()
    }, [])

    useEffect(() => {
      console.log(categories_array,"Heter",category)
    }, [categories_array])
    

    return (
      <div>
        <div className='products mt-3  '><ProductsList productsData={products_array} /></div>
        {add_product_flag&&
        <ProductsAddModal categories_array = {categories_array} />
        }
        <Notification/>
      </div>
    )
  // }

  // else{



  //   return(
  //     <></>
  //   )
  // }
}




const mapStateToProps = (state) => {
  const {
    products_array,
    categories_array,
    add_product_flag
  } = state.variables
  return {
    products_array,
    categories_array,
    add_product_flag
  }
}
const mapActionsToProps = {
  productsFetch,
  productsFetchbycategory,
  categoriesFetch
}
export default connect(mapStateToProps, mapActionsToProps)(Products)