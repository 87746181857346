import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"
export const login = (data) => {
    return (dispatch) => {
        loginHelper({ data, dispatch })
    }
}

export const loginHelper = async ({ data, dispatch }) => {
    const { email, password } = data
    const headers = {
        "Content-type": "application/json"
    }
    const body = JSON.stringify({
        "email": email,
        "password": password
    })
    const config = {
        headers,
        method: "POST",
        body
    }

    const url = base_url + "/user/loginuser"
    try {
        const response = await fetch(url, config)
        const response_json = await response.json()
        console.log(response_json, 28)
        if (response_json.status === 200) {
            if (response_json.data == true) {
                localStorage.setItem("token", response_json.token)
                dispatch({
                    type: CHANGE_VARIABLE,
                    payload: {
    
                        key: "isLogin",
                        value: true
    
                    }
                })
            }
            else {
                alert("You Are not Admin Cant Open Dashboard For you")
            }
        }
        else {
            alert("Login CanNot Possible wrong password or email")
        }

        // fetch(url,config).then(response=>response.json()).then(responseJson=>{
        //     console.log(responseJson)
        //     if(responseJson){
        //         dispatch({
        //             type:CHANGE_VARIABLE,
        //             payload:{
        //                 key:"products_array",
        //                 value:responseJson.data
        //             }
        //         })
        //     }



        // })


    }
    catch (err) {
        console.log(err)
    }
}
