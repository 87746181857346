import React from 'react'
import { connect } from 'react-redux'
import { changeVariable } from '../../actions/variables'
import CategoryUpdateModal from './CategoryUpdateModal'

const CategoryItem = ({
  value,
  modal_category_flag,

  changeVariable
}) => {

  const displayCategoryModal=()=>{
    changeVariable("modal_category_title",value.title)
    changeVariable("modal_category_onHome",value.onHome)
    changeVariable("modal_category_is_active",value.is_active)
    changeVariable("modal_category_image",value.image)
    changeVariable("modal_category_banner_image",value.banner_image)
    changeVariable("modal_category_flag",true)
    changeVariable("modal_category_id",value._id)
    changeVariable("modal_category_priority_id",value.priority_id)
    changeVariable("modal_category_mastHead",value.mastHead)
    changeVariable("modal_category_description",value.description)
    changeVariable("modal_category_banner",value.banner)
    console.log(value)
  }
  

  return (
    <>
    <div className="w-22 h-22  p-10 m-1 bg-white rounded border border-light-grey shadow cursor-pointer bg-slate-100" onClick={()=>{displayCategoryModal()}} >
      <div className=''>{value._id}</div>
			<div className="text-slate-900 text-base font-bold">{ value.title }</div>
			<div className="text-slate-900 text-base font-bold">Priority id { value.priority_id }</div>
    </div>
    {
      modal_category_flag&&<CategoryUpdateModal/>
    }

    </>
    )
}



const mapStateToProps = (state) => {
  const {
    modal_category_flag
  } = state.variables
  return {
    modal_category_flag
  }
}

const mapActionsToProps={
  changeVariable
}
export default connect(mapStateToProps,mapActionsToProps)(CategoryItem)