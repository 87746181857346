import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"
export const productDelete = (data) => {
    return (dispatch) => {
        productsDeleteHelper({ data, dispatch })
    }
}

export const productsDeleteHelper = async ({ data, dispatch }) => {
    const token = await localStorage.getItem("token")
    const headers = {

        "Content-Type": "application/json",
        "authorization": `${token}`

    }
    const config = {
        headers,
        method: "DELETE",
        body:JSON.stringify({productId:data})
    }
    console.log(data,"here");
    
    const url = base_url + `/products/delete`
    try {


        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "notification_flag",
                value: true

            }

        })
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "notification",
                value: "deleting product..."

            }

        })

        const response = await fetch(url, config)
        const responseJson = await response.json()
        console.log(responseJson)
        if (responseJson) {
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "notification",
                    value: responseJson.message

                }


            })
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "notification_flag",
                    value: false

                }

            })

        }
        else {
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "notification_flag",
                    value: true

                }

            })
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "notification",
                    value: responseJson.message

                }

            })
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "notification_flag",
                    value: false

                }

            })

        }
        // fetch(url,config).then(response=>response.json()).then(responseJson=>{
        //     console.log(responseJson)
        //     if(responseJson){
        //         dispatch({
        //             type:CHANGE_VARIABLE,
        //             payload:{
        //                 key:"products_array",
        //                 value:responseJson.data
        //             }
        //         })
        //     }



        // })


    }
    catch (err) {
        console.log(err)
    }
}
