import { CHANGE_VARIABLE } from "./type"
export const base_url = "https://starfish-app-kubrm.ondigitalocean.app"

// export const base_url = "https://gohworld-api.onrender.com"
export const changeVariable = (key, value) => {
	
    return ({

      type: CHANGE_VARIABLE,
      payload: {

          key,
          value

      }

    })
    
}
