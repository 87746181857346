import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"
import axios from "axios"
export const updateProduct = (file) => {

    return (dispatch) => {

        updateProductHelper({ file, dispatch })

    }

}
const updateProductHelper = async ({ file, dispatch }) => {

    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification_flag",
            value: true

        }

    })
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification",
            value: "uploading product"

        }

    })
    const formData = new FormData();
    formData.append('file', file);
    const headers = {

        // "Content-Type": "application/json",
        //     "Authorization": `Bearer ${token}`

    }

    // const config = {

    //     formData,
    //     headers,
    //     method: "POST",

    // }
    const url = base_url + "/upload/update-products"

    // console.log(config.body)

    try {
        // fetch(url, config)
        axios.patch(url, formData)
            .then(responseJson => {
                if (responseJson.status === 201) {
                    console.log(responseJson)
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification",
                            value: responseJson.data.message+`,   success:${responseJson.data.success},  failed:${responseJson.data.failed}`

                        }


                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification_flag",
                            value: false

                        }

                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "upload_data",
                            value: responseJson.data

                        }

                    })

                } else {

                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification_flag",
                            value: true

                        }

                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification",
                            value: responseJson.message

                        }

                    })
                    dispatch({

                        type: CHANGE_VARIABLE,
                        payload: {

                            key: "notification_flag",
                            value: false

                        }

                    })

                }

            })

    } catch (err) {

        console.log("error")

    }

}
