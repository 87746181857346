import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"

export const categoryUpdate = ( data ) => {

    return (dispatch)=> {

        categoryUpdateHelper({ data, dispatch })

    }

}
const categoryUpdateHelper = async ({ data, dispatch }) => {

    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification_flag",
            value: true

        }

    })
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification",
            value: "updating product..."

        }

    })
    const token = await localStorage.getItem("token")
    const headers = {

        "Content-Type": "application/json",
        "authorization": `${token}`

    }
    const body = JSON.stringify({
        title: data.category_title,
        onHome:data.category_onHome,
        image: !data.category_images?"":data.category_images.toString(),
        banner_image: !data.category_banner_image?"":data.category_banner_image.toString(),
        banner: data.category_banner,
        is_active: data.category_is_active,
        category_id:data.category_id,
        mastHead:data.category_mastHead,
        description:data.category_description,
        priority_id:parseInt(data.category_priority_id)
    })

    const config = {

        body,
        headers,
        method: "PATCH",

    }
    const url = base_url+"/category/patchcategory"

    // console.log( config )

    try {

        fetch(url, config)
        .then(response => response.json())
        .then(responseJson => {

            // console.log( responseJson )
            if( responseJson.status === 200 ){
                console.log(responseJson)
                // productsFetchByVendorHelper({ vendor_uuid: data.product_vendor_uuid, page: data.page_id, dispatch })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification",
                        value: responseJson.message

                    }

                    
                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: false

                    }

                })

            } else {

                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: true

                    }

                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification",
                        value: responseJson.message

                    }

                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: false

                    }

                })

            }

        })

    } catch (err) {

        console.log("error")

    }

}
