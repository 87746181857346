import React from 'react'
import { changeVariable } from '../../actions/variables'

const ContactItem = (props) => {
    const {
        name,
        email,
        phone,
        subject,
        message,
        created_at
    } = props.contactData

    const display_product_modal = () => {
		changeVariable("modal_contact_name")
		changeVariable("modal_contact_email")
		changeVariable("modal_contact_phone")
		changeVariable("modal_contact_subject")
		changeVariable("modal_contact_message")

	}

  return (
    <div className="w-fit h-44  p-2.5 m-1 bg-white rounded border border-light-grey shadow cursor-pointer bg-slate-100" onClick={() => { display_product_modal() }}  >
			<div className="text-slate-900 text-base font-bold">Subject {subject}</div>
			<div className="flex mt-1">
				<div className="text-black text-sm mr-2.5">{name}</div>
				<div className="text-black text-sm mr-2.5 ">{ email }</div>
				<div className="text-black text-sm">{phone }</div>
			</div>
			{/* <div className="text-grey text-xs mt-1">phone - {phone}</div> */}
			<div className="text-grey text-xs mt-1">is_active - {  }</div>
			<div className="text-grey text-xs mt-1">created at- {created_at}</div>
            <div>Message {message}</div>
		</div>
  )
}

export default ContactItem