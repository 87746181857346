import { CHANGE_VARIABLE } from "../type";


export const hideModal = (data) => {

    return (dispatch)=> {

        hideModalHelper({data, dispatch })

    }

}
export const hideModalHelper = async ({data, dispatch }) => {
    if(data==="productupdate"){
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "modal_display_flag",
            value: false

        }

    })

    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "modal_display_type",
            value: null

        }

    })}
    if(data==="productadd"){
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {
    
                key: "add_product_flag",
                value: false
    
            }
    
        })
    
    
    }
    if(data==="categoryupdate"){
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {
    
                key: "modal_category_flag",
                value: false
    
            }
    
        })
    
    
    }
    if(data==="categoryadd"){
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {
    
                key: "add_category_flag",
                value: false
    
            }
    
        })
    
    
    }
    if(data==="orderupdate"){
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {
    
                key: "modal_order_flag",
                value: false
    
            }
    
        })
    
    
    }
    if(data==="coupon_update_flag"){
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {
    
                key: "coupon_update_flag",
                value: false
    
            }
    
        })
    
    
    }
}
