import React from 'react'
import OrderItem from "./OrderItem"
const OrdersList = (props) => {
    const {

		ordersData

	} = props
  return (
    <div className="bg-white rounded border border-slate-300 p-5 shadow">
    <h1 className="text-2xl text-slate-900 font-bold">Orders</h1>
    <div className="mt-5 grid grid-cols-4 gap-2.5">
        {
            ordersData !== null && ordersData.map( ( value, index ) => {

                return(

                    <OrderItem
                        orderData={ value }
                        isLast={ index === ordersData.length - 1 }
                        key={ "order-item-" +index }
                    />

                )

            })

        }
    </div>
</div>
  )
}

export default OrdersList