import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { hideModal, orderAcceptPatch } from '../../actions'
import format from "date-fns/format"

const OrderDetailModal = ({
  order_data,
  modal_order_flag,

  // ACTIONS
  hideModal,
  orderAcceptPatch,
}) => {

  console.log(order_data,"srkmfrdf")
  console.log(modal_order_flag, "hereere")

  return (
    modal_order_flag &&
    <div className='fixed flex h-full justify-center left-0 w-full z-50' >
      <div className='bg-white h-3/4 p-4 relative  w-1/2 border overflow-y-scroll'>

        <div className='flex flex-row justify-between w-full'>
          <div className='flex flex-row flex-wrap'>
            <div className='align-center cursor-pointer flex h-4 justify-center ml-2 w-4' onClick={() => { hideModal("orderupdate") }}>
              <span className='h-3 w-2 font-bold'>X</span>
            </div>
          </div>
        </div>

        <div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-5 mt-2.5">
            <div className="border p-5 border-light-grey rounded">
              <div className="text-black text-xl font-bold">Customer Details</div>
              <div className="text-black text-l mt-5">{order_data && order_data.data.userName}</div>
              <div className="text-black text-xs uppercase text-grey">Customer Name</div>
              <div className="text-black text-l mt-5">{order_data && order_data.data.userEmail}</div>
              <div className="text-black text-xs uppercase text-grey">Customer email</div>
              <div className="text-black text-sm mt-2.5">{order_data && order_data.data.userAddress}</div>
              <div className="text-black text-sm">{order_data && order_data.data.userCity},{order_data && order_data.data.userState}</div>
              <div className="text-black text-sm">{order_data && order_data.data.userPincode}</div>
              <div className="text-black text-xs uppercase text-grey">Customer Address</div>
              <div className="text-black text-sm mt-2.5">{order_data && order_data.data.userPhone}</div>
              <div className="text-black text-xs uppercase text-grey">Customer Mobile</div>
            </div>
            <div className="border p-5 border-light-grey rounded w-fit">
              {/* <div className="text-black text-xl font-bold">Payment & Discount</div> */}
              {/* <div className="text-black text-l mt-5">{order_data.is_discounted ? "YES" : "NO"}</div> */}
              {/* <div className="text-black text-xs uppercase text-grey">Is Discounted?</div>
            <div className="text-black text-sm mt-2.5">{order_data.coupon === "" ? "-" : order_data.coupon}</div>
            <div className="text-black text-xs uppercase text-grey">Coupon Code</div> */}

              {/* <div className="text-black text-sm mt-2.5">₹{order_data.coupon_amount}</div>
            <div className="text-black text-xs uppercase text-grey">Coupon Amount</div> */}
              <div className="text-black text-sm mt-2.5">{order_data && order_data.data.PaymentType === false ? "COD" : "Prepaid"}</div>
              {/* <div className="text-black text-sm mt-2.5">COD</div> */}
              <div className="text-black text-xs uppercase text-grey">Payment Mode</div>
              
              <div className="text-black text-sm mt-2.5">{order_data && order_data.data.discounted === false ? "false" : "true"}</div>
              {/* <div className="text-black text-sm mt-2.5">COD</div> */}
              <div className="text-black text-xs uppercase text-grey">Discounted</div>
              

              <div className="text-black text-xs uppercase text-grey">razorpay payment id {order_data? order_data.data.PaymentType &&order_data.data.razorpay_payment_id:""}</div>
              <br /><div className="text-black text-xs uppercase text-grey">razorpay order id {order_data?order_data.data.PaymentType &&order_data.data.razorpay_order_id:""}</div>
              <br /><div className="text-black text-xs uppercase text-grey">razorpay signature {order_data?order_data.data.PaymentType &&order_data.data.razorpay_signature:""}</div>
            </div>
          </div>
          <div className="border p-5 border-light-grey rounded mt-5">
            <div className="text-black text-xl font-bold">Products</div>
            {

              order_data && order_data.product.map((value, index) => {

                console.log(value)
                return (
                  <div key={"product-order-" + index} className="mb-2.5">
                    <div className="text-black text-sm font-bold">{index + 1 + ". " + value.title}</div>
                    <div className="text-black text-sm">SKU: {order_data.data.sku}</div>
                    <div className="text-black text-sm">Price: ₹{order_data.data.price}</div>
                    <div className="text-black text-sm">Quantity: {order_data.data.items}</div>
                    <div className="text-black text-sm">Product Link: <a href={`http://localhost:4003/product/${order_data.data.productId}`}>clickhere</a></div>
                    <img src={value.image.split(",")[0]} alt="" style={{"width":"100px","height":"100px"}} />
                    {/* <Link href={web_url + "/" + value.product.url} passHref>
                    <a target="_blank">
                      <div className="text-grey text-sm underline">product link</div>
                    </a>
                  </Link> */}
                  </div>

                )
              })
            }
          </div>
          <div className="py-5">
            {order_data && <div className="text-sm text-slate-900">Order Time - {format(new Date(order_data.data.created_at), "do MMMM yyyy, hh:mm aa")}</div>}
          </div>
          {order_data ?

            !order_data.data.is_accepted &&
            <div className="flex">
              <button className="w-40 mt-2.5 text-xs h-12 rounded border-light-grey bg-blue-500 self-center uppercase text-white mr-5" onClick={() => { orderAcceptPatch(order_data.data._id) }} >accept</button>
              {/* <button className="w-40 mt-2.5 text-xs h-12 rounded border-light-grey bg-red-500 self-center uppercase text-white" >reject</button> */}
            </div>
            : ""
          }
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = (state) => {

  const {

    order_data,
    modal_order_flag

  } = state.variables

  return {

    order_data,
    modal_order_flag

  }

}

const mapActionsToProps = {

  hideModal,
  orderAcceptPatch,
  // orderRejectPATCH,

}


export default connect(mapStateToProps, mapActionsToProps)(OrderDetailModal)