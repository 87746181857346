import React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { ordersFetchAll } from '../../actions'
import OrderDetailModal from './OrderDetailModal'
import OrdersList from "./OrdersList"
const Orders = ({
  orders_array,
  orderType,

  ordersFetchAll
}) => {

  const location = useLocation()


  useEffect(() => {
    ordersFetchAll(orderType)
  }, [location])
  useEffect(() => {

  }, [orders_array])


  return (
    <>
      <OrderDetailModal />
      <div className='z-0'>
        <div className=' m-5 flex flex-row w-56 justify-between '>
          <Link to={"/orders/pending"}><div>Pending</div></Link>
          <Link to={"/orders/accepted"}><div>Approved</div></Link>
          <Link to={"/orders/cancelled"}><div>cancelled</div></Link>
        </div>

        <div><OrdersList ordersData={orders_array} /></div>

      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const {
    orders_array
  } = state.variables
  return {
    orders_array
  }
}

const mapActionsToProps = {
  ordersFetchAll
}

export default connect(mapStateToProps, mapActionsToProps)(Orders)