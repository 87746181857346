import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { hideModal } from "../../actions/modal"
import { productDelete, productUpdate } from "../../actions/products"
import { uploadFiles } from '../../actions/upload/uploadfile'

import ProductImages from "./ProductImages"


const ProductUpdateModal = ({
    modal_product_id,
    modal_product_title,
    modal_product_price,
    modal_product_full_price,
    modal_product_image,
    modal_product_type,
    modal_product_metal,
    modal_product_sku,
    modal_product_stock,
    modal_product_bullet_points,
    modal_product_description_short,
    modal_product_description,
    modal_product_category,
    modal_product_is_active,
    modal_product_display,
    modal_product_on_home,
    modal_display_flag,
    files,

    hideModal,
    productUpdate,
    uploadFiles,
    productDelete
}
) => {

    useEffect(() => {
        setState({
            ...state,
            product_id: modal_product_id,
            product_title: modal_product_title,
            product_price: modal_product_price,
            product_full_price: modal_product_full_price,
            product_images: typeof modal_product_image !== "undefined" && modal_product_image.length > 0 ? modal_product_image.split(",") : [],
            product_type: modal_product_type,
            product_metal: modal_product_metal,
            product_sku: modal_product_sku,
            product_bullet_points:modal_product_bullet_points,
            product_stock: modal_product_stock,
            product_description_short: modal_product_description_short,
            product_description: modal_product_description,
            product_category: modal_product_category,
            product_is_active:modal_product_is_active,
            product_on_home:modal_product_on_home
        })
    }, [modal_product_sku])

    const uploadFilesFunction = (event) => {

        uploadFiles(event.target.files)

    }

    const [images, setImages] = useState([])
    const [state, setState] = useState({
        product_id: "",
        product_title: "",
        product_price: "",
        product_full_price: "",
        product_images: [],
        product_type: "",
        product_metal: "",
        product_sku: "",
        product_bullet_points:"",
        product_stock: 0,
        product_description_short: "",
        product_description: "",
        product_category: "",
        product_is_active: true,
        product_on_home:false
    })


    const updateState = (event) => {
        const variable = event.target.name
        const value = event.target.value
        setState({ ...state, [variable]: value })
    }


    useEffect(() => {
        if (files !== null) {
            console.log("Here ", files)
            console.log("here2", files.data)
            // var images = []
            {files.data?
                files.data.map((value, index) => {
                    images.push(value.secure_url)
                }):
                images.push(files.secure_url)
                console.log(files,"file")
            }
            // if (files.data.length > 0) {
            //     files.data.map((value, index) => {
            //         images.push(value.secure_url)
            //     })
            // }
            // else {
            //     images.push(files.secure_url)
            // }

            setState({ ...state, product_images: images })
        }
    }, [files])



    return (
        <div>

            {/* <button data-modal-target="defaultModal" data-modal-toggle="defaultModal" class="block text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                Toggle modal
            </button> */}

            {modal_display_flag &&
                <div id="defaultModal" tabindex="-1" aria-hidden="true" className="fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
                    <div class="relative w-full h-full max-w-2xl md:h-auto">

                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-200">


                            <form className='p-5'>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="email" onChange={(e) => { updateState(e) }} value={state.product_title} name="product_title" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Title</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_price} name="product_price" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Price</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_full_price} name="product_full_price" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Full Price</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_type} name="product_type" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Type</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_metal} name="product_metal" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Metal</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_sku} name="product_sku" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Sku</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_stock} name="product_stock" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">stock</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_bullet_points} name="product_bullet_points" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Bullet Points (add commas to seperate the points)</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_description} name="product_description" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">description</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_description_short} name="product_description_short" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">description short</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group">
                                    <input type="text" onChange={(e) => { updateState(e) }} value={state.product_category} name="product_category" id="floating_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " required />
                                    <label for="floating_email" class="peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">category</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group flex items-center">
                                    <input type="checkbox"  onChange={(e) => { setState({...state,product_is_active:state.product_is_active===true?false:true}) }} checked={state.product_is_active} name="product_is_active" id="floating_email" class="block mr-2 " placeholder=" " required />
                                    <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">is_active</label>
                                </div>
                                <div class="relative z-0 w-full mb-6 group flex items-center">
                                    <input type="checkbox"  onChange={(e) => { setState({...state,product_on_home:state.product_on_home===true?false:true}) }} checked={state.product_on_home} name="product_on_home" id="floating_email" class="block mr-2 " placeholder=" " required />
                                    <label for="floating_email" class=" ml-5 peer-focus:font-medium absolute text-lg text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">on Home</label>
                                </div>
                                <div className='flex justify-between'>
                                    <div>
                                        <button type="button" onClick={() => { productUpdate(state) }} class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                                        <button type='button' className="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={()=>{productDelete(state.product_id)}} >Delete</button>
                                    </div>
                                    <button type='button' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800' onClick={() => { hideModal("productupdate") }} >Close</button>
                                </div>
                            </form>
                            <div>
                                <ProductImages images={state.product_images} />
                                <form >
                                    <div class="relative z-0 w-full mb-6 group">

                                        <input type="file" name="product_images" onChange={(files) => { uploadFilesFunction(files) }} multiple={true} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " />
                                        <label for="floating_email" class="">Main Image</label>
                                    </div>
                                    <div class="relative z-0 w-full mb-6 group">

                                        <input type="file" name="product_images" onChange={(files) => { uploadFilesFunction(files) }} multiple={true} class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer " placeholder=" " />
                                        <label for="floating_email" class="">Other Images</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>}
        </div>
    )
}


const mapStateToProps = (state) => {
    const {
        modal_product_id,
        modal_product_title,
        modal_product_price,
        modal_product_full_price,
        modal_product_image,
        modal_product_type,
        modal_product_bullet_points,
        modal_product_metal,
        modal_product_sku,
        modal_product_stock,
        modal_product_description_short,
        modal_product_description,
        modal_product_category,
        modal_product_is_active,
        modal_product_on_home,
        modal_product_display,
        modal_display_flag,
        files
    } = state.variables
    return {
        modal_product_id,
        modal_product_title,
        modal_product_price,
        modal_product_full_price,
        modal_product_image,
        modal_product_type,
        modal_product_bullet_points,
        modal_product_metal,
        modal_product_sku,
        modal_product_stock,
        modal_product_description_short,
        modal_product_description,
        modal_product_category,
        modal_product_is_active,
        modal_product_on_home,
        modal_product_display,
        modal_display_flag,
        files
    }
}

const mapActionsToProps = {
    hideModal,
    productUpdate,
    uploadFiles,
    productDelete
}



export default connect(mapStateToProps, mapActionsToProps)(ProductUpdateModal)

