import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"

export const productAdd = ( data ) => {

    return (dispatch)=> {

        productAddHelper({ data, dispatch })

    }

}
const productAddHelper = async ({ data, dispatch }) => {

    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification_flag",
            value: true

        }

    })
    dispatch({

        type: CHANGE_VARIABLE,
        payload: {

            key: "notification",
            value: "updating product..."

        }

    })
    // console.table( data )
    const token = await localStorage.getItem("token")
    const headers = {

        "Content-Type": "application/json",
        "authorization": `${token}`

    }
    const body = JSON.stringify({
        title: data.product_title,
        description: data.product_description,
        description_short: data.product_description_short,
        image: data.product_images.toString(),
        is_active: data.product_is_active,
        price: data.product_price,
        sku: data.product_sku,
        stock: data.product_stock,
        metal:data.product_metal,
        category: data.product_category,
        productId:data.product_id,
        type:data.product_type,
    })

    // console.log({

    //     is_variant: data.product_is_variation,
    //     variant_uuid: data.product_variation_uuid,
    //     parent_sku: data.product_parent_sku,

    // })
    // console.log(data.product_images.toString(),)
    const config = {

        body,
        headers,
        method: "POST",

    }
    const url = base_url+"/products/addproduct"

    console.log( config.body )

    try {

        fetch(url, config)
        .then(response => response.json())
        .then(responseJson => {

            // console.log( responseJson )
            if( responseJson.status === 200 ){
                console.log(responseJson)
                // productsFetchByVendorHelper({ vendor_uuid: data.product_vendor_uuid, page: data.page_id, dispatch })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification",
                        value: responseJson.message

                    }

                    
                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: false

                    }

                })

            } else {

                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: true

                    }

                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification",
                        value: responseJson.message

                    }

                })
                dispatch({

                    type: CHANGE_VARIABLE,
                    payload: {

                        key: "notification_flag",
                        value: false

                    }

                })

            }

        })

    } catch (err) {

        console.log("error")

    }

}
