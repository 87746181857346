import React, { useState } from 'react'
import { connect } from 'react-redux'
import { login } from "../../actions/index"
import { useEffect } from 'react'
const Login = ({
    login
}) => {

    const [state, setState] = useState({ email: "", password: "" })
    const updateState = (event) => {
        const name = event.target.name
        const value = event.target.value
        setState({ ...state, [name]: value })
    }

    const LoginFunc = (e) => {
        e.preventDefault()
        login(state)
    }

    
    return (
        <div className="bg-gray-100 min-h-screen flex items-center justify-center">

            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                <h1 className="text-2xl font-semibold mb-4 text-center">Gurantee Ornament House</h1>
                <form className='' method='post'>
                    <div className="mb-4">
                        <label for="email" className="block text-sm font-medium text-gray-700">Username</label>
                        <input type="email" id="email" onChange={(e) => { updateState(e) }} value={state.email} name="email" className="mt-1 p-2 w-full border rounded-md" />
                    </div>
                    <div className="mb-4">
                        <label for="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input type="password" onChange={(e) => { updateState(e) }} value={state.password} id="password" name="password" className="mt-1 p-2 w-full border rounded-md" />
                    </div>
                    <button type="submit" onClick={(e) => { LoginFunc(e) }} className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200">Login</button>
                </form>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    const {

    } = state.variables
    return {

    }
}

const mapActionsToProps = {
    login
}

export default connect(mapStateToProps, mapActionsToProps)(Login)