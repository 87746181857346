// import { base_url } from "./variables"

import FormData from "form-data"
import axios from "axios"
import { CHANGE_VARIABLE } from "../type"
import { base_url } from "../variables"

export const uploadFiles = (file, banner) => {

    return (dispatch) => {

        uploadFilesHelper({ file, banner, dispatch })

    }

}
const uploadFilesHelper = async ({ file, banner, dispatch }) => {

    try {

        var formData = new FormData()
        for (var i = 0; i < file.length; i++) {

            formData.append("photo", file[i])

        }
        if (banner == true) {
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "banner",
                    value: true

                }

            })
        } else {
            dispatch({

                type: CHANGE_VARIABLE,
                payload: {

                    key: "banner",
                    value: false

                }

            })
        }
        const url = base_url + "/upload/image"
        const config = {

            // headers: { "content-type": "multipart/form-data" },
            // onUploadProgress: (event) => {

            //     console.log(`Current progress:`, Math.round((event.loaded * 100) / event.total))
            //     const progress = Math.round((event.loaded * 100) / event.total)
            //     dispatch({

            //         type: CHANGE_VARIABLE,
            //         payload: {

            //             key: "notification",
            //             value: "uploading -" + progress +"%"

            //         }

            //     })
            //     dispatch({

            //         type: CHANGE_VARIABLE,
            //         payload: {

            //             key: "notification_flag",
            //             value: true

            //         }

            //     })
            //     if( +progress === 100 )
            //         dispatch({

            //             type: CHANGE_VARIABLE,
            //             payload: {

            //                 key: "notification",
            //                 value: "processing.."

            //             }

            //         })

            // },

        }
        const response = await axios.post(url, formData, config)
        console.log(response)
        // FILE COMPLETED PROCESSING
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "notification",
                value: "File Uploaded."

            }

        })
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "notification_flag",
                value: false

            }

        })
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "files",
                value: response.data
            }

        })
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "image",
                value: response.data.url

            }

        })
        dispatch({

            type: CHANGE_VARIABLE,
            payload: {

                key: "image_id",
                value: response.data.files.cloudflare_id

            }

        })

        console.log("response", response)

    } catch (err) {

        // console.log( err )
        console.log("error")

    }

}

