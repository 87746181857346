import { base_url } from "../variables"
import { CHANGE_VARIABLE } from "../type"
export const checkLoggedIn = () => {
    return (dispatch) => {
        checkLoggedInHelper({dispatch })
    }
}

export const checkLoggedInHelper = async ({ dispatch }) => {
    const token = await localStorage.getItem("token")
    
    const headers = {
        "Content-type": "application/json",
        "authorization":token
    }
    const config = {
        headers,
        method: "POST",
        
    }

    const url = base_url + "/user/checkuser"
    try {
        const response = await fetch(url, config)
        const response_json = await response.json()
        console.log(response_json,26)
        if (response_json.status === 200) {
            if (response_json.data.isAdmin == true) {
                dispatch({
                    type: CHANGE_VARIABLE,
                    payload: {
    
                        key: "isLogin",
                        value: true
    
                    }
                })
            }
           
        }
       

        // fetch(url,config).then(response=>response.json()).then(responseJson=>{
        //     console.log(responseJson)
        //     if(responseJson){
        //         dispatch({
        //             type:CHANGE_VARIABLE,
        //             payload:{
        //                 key:"products_array",
        //                 value:responseJson.data
        //             }
        //         })
        //     }



        // })


    }
    catch (err) {
        console.log(err)
    }
}
